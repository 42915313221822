.login-form {
  width: 100%;
  max-width: 436px;
  margin: auto;

  .field {
    display: flex;
    flex-direction: column;
    margin-bottom: 42px;

    input {
      height: 32px;
      border: 0;
      display: flex;
      box-sizing: border-box;
      border-bottom: 1px solid #000;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 5px;

      &::placeholder {
        color: rgba(221, 221, 221, 1);
      }

      &:focus {
        outline: none;
        //border-bottom: 2px solid #000;
      }
    }

    .label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      label {
        font-family: "CoFo Robert";
        font-style: italic;
        font-weight: 250;
        font-size: 17px;
        line-height: 20px;
        color: #000000;
      }
    }
  }
}

.email-requested {
  width: 100%;
  max-width: 436px;
  margin: auto;
  font-family: "Favorit";
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

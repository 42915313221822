.app-outlet {
  padding: 32px 0;
  /* height: 100%; */
  display: flex;
  padding-top: 112px;
  margin-top: -80px;
  justify-content: center;
  /* align-items: center; */
}

.badge {
  font-family: "Favorit";
  font-style: normal;
  font-weight: 350;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  background: #a0a0a0;
  border-radius: 4px;
  padding: 2px;
  margin-right: 4px;

  &.inappropriate {
    padding: 2px 6px;
    background: #ff3200;
  }

  &.appropriate {
    padding: 2px 6px;
    background: #026c02;
  }
}

.heading {
  font-family: "Favorit";
  font-style: normal;
  font-weight: 350;
  font-size: 40px;
  line-height: 44px;
  transition: color 300ms;
  margin: 0;

  &.white {
    color: #fff;
  }
}

.pointer {
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 20px;
}

.caption {
  font-family: "Favorit";
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  transition: color 300ms;

  color: #000000;

  &.small {
    font-size: 12px;
    line-height: 16px;
  }
  &.large {
    font-size: 24px;
    line-height: 32px;
  }

  &.white {
    color: #ffffff;
  }
  &.grey {
    color: #a0a0a0;
  }
  &.light {
    color: #dddddd;
  }
  &.red {
    color: #FF3200;
  }

  &.accented {
    font-family: "CoFo Robert";
    font-style: italic;
    font-weight: 250;
    font-size: 17.5px;
    line-height: 20px;
    font-feature-settings: "tnum" on, "lnum" on;

    &.large {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &.upper-case {
    text-transform: uppercase;
  }

  &.bordered {
    border: 1px solid currentcolor;
    display: inline-block;
    padding: 0 4px;
    border-radius: 4px;
    margin-right: 2px;
  }

  &.inline {
    margin-right: 2px;
    display: inline-block;
  }
}

.caption_bold {
  @extend .caption;
  font-weight: 700;
}

.app-loader {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  img {
    height: 48px;
    width: 48px;
  }
}

.app-select {
  &__input {
    &::placeholder {
      color: #DDDDDD!important;
    }
  }
  &__placeholder {
    color: #DDDDDD!important;
  }
  &-container {
  }
  &__menu {
    z-index: 9!important;
  }
  &__indicator-separator {
    display: none;
  }
  &__indicators {
    border-radius: 8px !important;
  }
  &__multi-value__label, &__option {
    @extend .caption;
    //font-size: 16px!important;
  }
  &__multi-value__remove {
    cursor: pointer;

    &:hover {
      background-color: #ff3200!important;
      color: #ffffff!important;
    }
  }
  &__clear-indicator {
    cursor: pointer;
  }
  &__dropdown-indicator{
    color: hsl(0deg 0% 100%) !important;
    background-color: #ff3200;
    border-radius: 0px 8px 8px 0px;
    height: 100%;
    width: 48px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      color: hsl(0deg 0% 100%);
    }
  }
  &__control {
    min-height: 48px !important;
    border-radius: 8px !important;
    border: 1px solid #dddddd !important;
    box-shadow: none !important;

    &--is-focused,
    &:hover {
      border: 1px solid #dddddd !important;
    }
  }

  &__error {
    .app-select__control {
      border: 1px solid #ff3200!important;
    }
  }

  &__menu {
    border-radius: 0 0 8px 8px !important;
    margin-top: 0px !important;
    width: calc(100% - 48px) !important;

    &-list {
      padding: 0px !important;
    }
  }

  &__option {
    background-color: #ffffff !important;
    color: #a0a0a0 !important;
    border-bottom: 1px solid #f5f5f5;

    &--is-selected,
    &:hover {
      color: #000 !important;
    }
  }
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.next-enter {
  opacity: 0;
}
.next-enter-active {
  opacity: 1;
}
.next-exit {
  opacity: 1;
}
.next-exit-active {
  opacity: 0;
}

.blocks {
  width: 768px;
  margin-bottom: 96px;
  align-self: center;
  filter: drop-shadow(0px 4px 64px rgba(0, 0, 0, 0.1));

  .center {
    width: 372px;
    margin: auto;

    .app-select-container {
      margin-top: 40px;
      margin-bottom: 52px;
    }
  }
}

.block {
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 8px;
  padding: 12px;
  padding-top: 0px;
  padding-bottom: 0px;

  &_last_change {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 120px 0 96px 0;
    position: relative;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: calc((768px - 100vw) / 2);
      height: 1px;
      width: 100vw;
      background-color: #ff3200;
      content: "";
    }

    .caption {
      color: #ff3200;
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 24px;
      position: relative;
      top: -24px;
    }
  }

  &_title {
    font-family: "Favorit";
    font-style: normal;
    font-weight: 350;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 4px;

    &.top {
      border-bottom: 1px solid #f5f5f5;
      margin: 0 -12px;
      padding: 0 12px 8px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .red {
        cursor: pointer;
      }
    }
  }

  &_content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    &.link {
      .block_validations,
      .block_data {
        padding-top: 0px;
        box-sizing: border-box;
        border: none;
      }
      .block_data {
        border-right: 1px solid #f5f5f5;
      }
    }

    &.question {
      position: relative;

      &::before {
        position: absolute;
        left: -12px;
        right: -12px;
        bottom: -1px;
        content: ' ';
        border-bottom: 1px solid #f5f5f5;
      }

      .block_validations,
      .block_data {
        padding-top: 0px;
        border: 0;
        box-sizing: border-box;
      }
    }
  }

  &_validations,
  &_data {
    display: flex;
    padding-top: 12px;
  }

  &_data {
    display: flex;
    flex-direction: column;
    width: 343px;

    &.full {
      width: 100%;
      border-right: 0;
    }
  }

  &_validations {
    flex: 1;
    flex-direction: column;
    border-left: 1px solid #f5f5f5;
    position: relative;
  }

  &.top {
    padding-top: 12px;
    .block_data {
      padding-top: 12px;
    }
  }

  &.empty_value {
    .block_data * {
      color: #a0a0a0;
    }
  }
}

.form-row {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form-column {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.ant-picker {
  border: 0;
  padding: 0;

  &-status-error {
    .ant-picker-input {
      border: 1px solid #ff3200;
    }
  }

  &-focused,
  &:hover {
    border-right-width: 0;
    box-shadow: none;
  }
  &-clear {
    right: 8px;
  }
  &-input {
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding-right: 8px;
    > input {
      box-sizing: border-box;
      padding: 14px 16px;
      height: 48px;
    }
    &:hover,
    &:focus {
      border: 1px solid #000;
    }
  }

  &-today-btn {
    color: #ff3200;

    &:hover {
      opacity: 0.8;
      color: #ff3200;
    }
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #ff3200;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #ff3200;
}


.breadcrumbs {
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;

  .breadcrumb {
    border-left: 1px solid #DDDDDD;
    margin-left: 12px;
    padding-left: 12px;
  }
}
.button {
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  font-family: 'Favorit';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  user-select: none;
  // & ~ & {
  //   background-color: tomato !important;
  // }
  :global(.lime) & {
    background-color: palevioletred !important;
  }

  &.red {
    background-color: #FF3200;
  }
  &.black {
    background-color: black;
  }

  &.disabled {
    background-color: #DDDDDD!important;
    cursor: default;
    opacity: 1!important;
  }

  &:hover {
    opacity: 0.8;
  }
}

@keyframes slide {
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(100%)
  }
};

.loader {
  width: 100%;
  height: 1px;
  background-color: #fff;
  animation: slide 1.4s linear infinite;
  animation-delay: 0.6s;
}

.standard {
  width: 248px;
}